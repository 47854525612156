import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";


  const Dashboard = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Dashboard/Dashboard")), 2000);
  });});
  const User = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./User/User")), 0);
  });});
  const Main = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Main/Main")), 2000);
  });});

function App() {

    return ( <> 
 
        <Router>
          <Routes>
       
          <Route exact path='/Dashboard'   element={
              <React.Suspense fallback={<Loader/>}>
                <Dashboard />
              </React.Suspense>
            }/>
        
        <Route exact path='/:id'   element={
              <React.Suspense fallback={<Loader/>}>
                <User id={window.location.pathname.replace('/','').trim()}/>
              </React.Suspense>
            }/>
        <Route exact path='/'   element={
              <React.Suspense fallback={<Loader/>}>
                <Main />
              </React.Suspense>
            }/>
     </Routes>

 

 </Router>
      
 </>);
}
function Loader(){
  return(
    <div className="loader animate__animated animate__fadeIn">
        <center><img src="/image/loading.gif"  style={{width:'40%'}}/></center>
    </div>
  )
}
export default App;